import React, { useState } from "react";
import { getAuth, deleteUser, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";

const DeleteAccountPage = () => {
  const [confirmationText, setConfirmationText] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    if (confirmationText.toLowerCase() !== "delete") {
      setError("Please type 'Delete' to confirm.");
      return;
    }

    try {
      const user = auth.currentUser;

      if (user) {
        // Delete the user's account
        await deleteUser(user);

        // Sign the user out after deletion
        await signOut(auth);

        setMessage("Your account has been successfully deleted.");
        navigate("/login");
      } else {
        setError("User not authenticated. Please log in again.");
      }
    } catch (err) {
      console.error("Error deleting account:", err);
      setError("Failed to delete the account. Please try again later.");
    }
  };

  if (!auth.currentUser) {
    // Render the sign-in message and button
    return (
      <div style={{ maxWidth: "600px", margin: "auto", padding: "20px" }}>
        <h1>Delete Account</h1>
        <p>
          You need to sign in to delete your account. Please click the button
          below to sign in and access this feature.
        </p>
        <button
          onClick={() => navigate("/login?redirect=delete-account")}
          style={{
            backgroundColor: "#007bff",
            color: "white",
            padding: "10px 20px",
            border: "none",
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          Sign In
        </button>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: "600px", margin: "auto", padding: "20px" }}>
      <h1>Delete Account</h1>
      <p>
        <strong>Important:</strong> Once you delete your account, you will no
        longer be able to access your account information, course purchases,
        and any active enrollments. Please type <strong>"Delete"</strong> in the
        textbox below to confirm deletion of your account.
      </p>

      <div>
        <label>
          Type "Delete" to confirm:
          <input
            type="text"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            placeholder="Type 'Delete' here"
            style={{
              display: "block",
              margin: "10px 0",
              padding: "8px",
              width: "100%",
            }}
          />
        </label>
      </div>

      {message && <p style={{ color: "green" }}>{message}</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}

      <button
        onClick={handleDeleteAccount}
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "10px 20px",
          border: "none",
          cursor: "pointer",
          marginTop: "10px",
        }}
      >
        Delete Account
      </button>
    </div>
  );
};

export default DeleteAccountPage;
