import React, { useEffect, useState } from 'react';
import './PrivacyPolicy.css';
import { Container, Row, Col } from 'react-bootstrap';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase';

const PrivacyPolicy = ({ language }) => {
  const [translatedTexts, setTranslatedTexts] = useState(null);

  // Fetch translations for the Privacy Policy based on the selected language
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const docRef = doc(db, 'translations', 'PrivacyPolicy');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const translationData = JSON.parse(data[language]); // Parse JSON string for the selected language
          setTranslatedTexts(translationData);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchTranslations();
  }, [language]);

  return (
    <div className="privacy-policy">
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            {translatedTexts ? (
              <>
                <h1 className="privacy-policy-title">{translatedTexts.title}</h1>
                <p className="privacy-policy-intro">{translatedTexts.introduction}</p>
                
                <h2 className="privacy-policy-section-title">{translatedTexts.section1Title}</h2>
                <p className="privacy-policy-text">{translatedTexts.section1Content}</p>
                
                <h2 className="privacy-policy-section-title">{translatedTexts.section2Title}</h2>
                <p className="privacy-policy-text">{translatedTexts.section2Content}</p>
                
                <h2 className="privacy-policy-section-title">{translatedTexts.section3Title}</h2>
                <p className="privacy-policy-text">{translatedTexts.section3Content}</p>
                
                <h2 className="privacy-policy-section-title">{translatedTexts.section4Title}</h2>
                <p className="privacy-policy-text">{translatedTexts.section4Content}</p>
                
                <h2 className="privacy-policy-section-title">{translatedTexts.section5Title}</h2>
                <p className="privacy-policy-text">{translatedTexts.section5Content}</p>
                
                <h2 className="privacy-policy-section-title">{translatedTexts.section6Title}</h2>
                <p className="privacy-policy-text">{translatedTexts.section6Content}</p>
                
                <h2 className="privacy-policy-section-title">{translatedTexts.section7Title}</h2>
                <p className="privacy-policy-text">{translatedTexts.section7Content}</p>
              </>
            ) : (
              <p>Loading Privacy Policy...</p>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
